import React, { useRef, useState } from 'react';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import BlogSlider from '../components/views/BlogSlider/BlogSlider';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { BlogPageQuery } from '../../generated/graphql-types';
import Button from '../components/core/buttons/Button';
import SEO from '../components/core/meta/SEO';
import BackgroundImage from 'gatsby-background-image';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import { convertToBgImage } from 'gbimage-bridge';

const PER_PAGE = 12;

const OffsetSection = styled.section`
  position: relative;

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    height: 60px;
    top: -60px;
  }
`;

const BlogPage = () => {
  const [page, setPage] = useState<number>(1);
  const blogsRef = useRef();

  const handlePageChange = (e: any, value: number) => {
    setPage(value);

    if (blogsRef?.current) {
      window.scrollTo({
        behavior: 'smooth',
        // @ts-ignore
        top: blogsRef.current.offsetTop - 60,
      });
    }
  };

  const blogPageData = useStaticQuery<BlogPageQuery>(graphql`
    query BlogPage {
      image: file(relativePath: { eq: "meta-blog.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      blogs: allContentfulBlog(sort: { order: DESC, fields: date }) {
        nodes {
          date(locale: "it", formatString: "MMM DD. yyyy")
          title
          slug
          metaDescription
          metaTitle
          ctaButtonText
          ctaImage {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          ctaText {
            raw
          }
          ctaUrl
          content {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  width: 2000
                )
              }
            }
          }
          summary
          isDummy
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  console.log(blogPageData.blogs.nodes.filter(i => !i.image).map(i => i.slug));

  return (
    <>
      <SEO
        title="Blog"
        metaImage={blogPageData.image.childImageSharp}
        url="/blog"
        description="Il blog di HelloUGO cerca di offrire risposte e nuove soluzioni per affrontare e vivere al meglio la fragilità. Segui i nostri aggiornamenti."
      />
      <div className="pb-20">
        <section className="pt-16">
          <Container>
            <Heading variant="h1" className="text-center mb-4 md:mb-0">
              Benvenuti nel Blog di UGO
            </Heading>
            <Heading
              variant="h5"
              className="text-primary-500 text-center mb-20"
              style={{ color: '#f9b233' }}
            >
              Ci sono risposte e nuove soluzioni per vivere al meglio la
              fragilità.
            </Heading>
            <div>
              <BlogSlider
                rounded={true}
                blogs={blogPageData.blogs.nodes.filter(b => !b.isDummy)}
              />
            </div>
          </Container>
        </section>
        <OffsetSection className="pt-16" ref={blogsRef}>
          <Container>
            <div className="flex flex-wrap -mx-6">
              {blogPageData.blogs.nodes
                .filter(b => !b.isDummy)
                .slice((page - 1) * PER_PAGE, page * PER_PAGE)
                .map(b => {
                  const img = convertToBgImage(b.image.gatsbyImageData);
                  return (
                    <div
                      className="w-full sm:w-1/2 lg:w-1/3 p-6 flex"
                      key={b.slug}
                    >
                      <div className="bg-white w-full rounded-lg flex-1 flex flex-col overflow-hidden">
                        <BackgroundImage style={{ height: '250px' }} {...img} />
                        <div className="flex flex-col flex-1">
                          <div className="px-8 pt-8 mb-auto">
                            <Heading variant="h6">{b.title}</Heading>
                            <span className="text-gray-400">{b.date}</span>
                            <p className="text-paragraph mb-5">{b.summary}</p>
                          </div>
                          <div className="px-8 pb-8">
                            <Link to={'/blog/' + b.slug + '/'}>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                // className="px-14"
                                style={{
                                  paddingLeft: '3.5rem',
                                  paddingRight: '3.5rem',
                                }}
                              >
                                Leggi tutto
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Pagination
              page={Number(page)}
              //@ts-ignore
              onChange={handlePageChange}
              count={Math.ceil(blogPageData.blogs.nodes.length / PER_PAGE)}
              color="primary"
            />
          </Container>
        </OffsetSection>
      </div>
    </>
  );
};

export default BlogPage;
